const languageObj = {
  ae: ['ae', 'ar-AE', 'ar'],
  en: ['en', 'en-AU', 'en-BZ', 'en-CA', 'en-CB', 'en-GB', 'en-IE', 'en-JM', 'en-NZ', 'en-PH', 'en-TT', 'en-US', 'en-ZA', 'en-ZW'],
  es: ['es', 'es-AR', 'es-BO', 'es-CL', 'es-CO', 'es-CR', 'es-DO', 'es-EC', 'es-ES', 'es-GT', 'es-HN', 'es-MX', 'es-NI', 'es-PA', 'es-PE', 'es-PR', 'es-PY', 'es-SV', 'es-UY', 'es-VE'],
  id: ['id', 'id-ID'],
  kr: ['kr', 'ko', 'ko-KR'],
  pt: ['pt', 'pt-BR', 'pt-PT'],
  zh: ['zh', 'zh-CN', 'zh-HK', 'zh-MO', 'zh-SG', 'zh-TW'],
  ru: ['ru', 'ru-RU'],
  de: ['de'],
  it: ['it']
};

const localeSubpaths = {
  // en: 'en',
  ru: 'ru',
  ae: 'ae',
  es: 'es',
  id: 'id',
  hi: 'hi',
  kr: 'kr',
  pt: 'pt',
  zh: 'zh',
  de: 'de',
  it: 'it'
};

module.exports = { languageObj, localeSubpaths };
